import { FormType } from '@/constant/form';
import Table from '@/constant/table';
import moment from '@/utils/moment';
import { formatKeyValueArray } from '@/utils/base';
import getTemperatureLayer from '@/utils/temperatureLayer';

const DELIVER_TYPE_ENUM = {
  ORDER_OUT: '订单出库',
  ALLOT_OUT: '调拨出库',
  SHIFT_PARKING_OUT: '移库移位',
  ADJUST_OUT: '调整出库',
  INVENTORY_LESS: '盘亏出库',
  DESTROY_OUT: '销毁出库',
  CANCEL_OUT: '退供出库',
  TOTAL_PICK_DOWN: '总拣下架',
  TEMPORARY_OUT: '零担出库',
  OTHER_IT_OUT: '其它出库',
  OTHER_HE_OUT: '其他出库',
  ALLOT_OUT_BOUND_IN: '调拨出库(库内)',
  JYP_OUT: '经由品出库',
};
const DELIVER_TYPE = formatKeyValueArray(DELIVER_TYPE_ENUM);
const CROSS_DOCKING_ENUM = {
  true: '是',
  false: '否',
};
export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  },
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    minWidth: Table.TIME_COLUMN_WIDTH,
    formatter: ({ bookingTime }) => moment.format(bookingTime),
  }, {
    label: '出库单号',
    prop: 'deliverOrderNo',
    minWidth: 150,
  }, {
    label: '波次号',
    prop: 'waveOrderNo',
    minWidth: 150,
  }, {
    label: '订单类型',
    prop: 'deliverType',
    width: 80,
    formatter: ({ deliverType }) => DELIVER_TYPE_ENUM[deliverType],
  }, {
    label: '货主订单号',
    prop: 'customerOrderNo',
    minWidth: 160,
  }, {
    label: '货主',
    prop: 'shipperName',
    width: 120,
  }, {
    label: '站点名称',
    prop: 'stationName',
    width: 160,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    width: 120,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    width: 120,
  }, {
    label: '货品规格',
    prop: 'spec',
    width: 100,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    width: 80,
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    width: 80,
    formatter: ({ temperatureLayer }) => getTemperatureLayer(temperatureLayer),
  }, {
    label: '批次号',
    prop: 'batchNo',
    width: 160,
  }, {
    label: '生产日期',
    prop: 'productDate',
    width: 160,
    formatter: ({ productDate }) => moment.format(productDate),
  }, {
    label: '柜号',
    prop: 'boxNo',
    width: 120,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    width: 80,
  }, {
    label: '出库数量(默认单位)',
    prop: 'defaultUnitAmount',
    width: 160,
  }, {
    label: '整单位数量',
    prop: 'bigUnitNumber',
    width: 100,
  }, {
    label: '零单位数量',
    prop: 'smallUnitNumber',
    width: 100,
  }, {
    label: '出库重量(KG)',
    prop: 'totalWeight',
    width: 120,
  }, {
    label: '出库体积（m³）',
    prop: 'totalVolume',
    width: 130,
  }, {
    label: '出库板数',
    prop: 'veneer',
    width: 80,
  }, {
    label: '是否越库',
    prop: 'crossDocking',
    width: 80,
    formatter: ({ crossDocking }) => CROSS_DOCKING_ENUM[crossDocking],
  }, {
    label: '发运时间',
    prop: 'deliverTime',
    width: Table.TIME_COLUMN_WIDTH,
    formatter: ({ deliverTime }) => moment.format(deliverTime),
  }];
export const serchFields = [
  {
    label: '出库单号',
    prop: 'deliverOrderNo',
    component: FormType.INPUT,
  }, {
    label: '出库单类型',
    prop: 'deliverType',
    component: FormType.SELECT,
    options: DELIVER_TYPE,
  }, {
    label: '波次号',
    prop: 'waveOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    component: FormType.INPUT,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '货主订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '批次号',
    prop: 'batchNo',
    component: FormType.INPUT,
  }, {
    label: '配送站点',
    prop: 'stationName',
    component: FormType.INPUT,
  }, {
    label: '创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  }, {
    label: '发运时间',
    prop: 'deliverTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  },
];
