import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:出库单记录表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16233
 */
export function deliverOrderRecord(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order_record/page', data, { params });
}
/**
 * @description: 导出出库单记录
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16269
 */

export function deliverOrderRecordExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/deliver_order_record/export', data, { fileName: '出库单记录明细', extension: 'xlsx' });
}
