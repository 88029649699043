<template>
  <nh-detail-page>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <div class="flex_column full-content">
        <PlTable
          v-model:pagination="pagination"
          :loading="loadingTable"
          :data="tableData"
          :columns="TABLECOLUMN"
          :show-table-setting="true"
          show-summary
          :summary-method="handleGetSummaries"
          @update:pagination="handlePaginationChange"
        >
          <template #tableHeaderRight>
            <nh-button
              type="primary"
              plain
              :track="{
                trackName: '报表中心/出库明细/[导出]'
              }"
              @click="handleExport"
            >
              导出
            </nh-button>
          </template>
        </PlTable>
      </div>
    </pl-block>
  </nh-detail-page>
</template>
<script>
import loadingMixin from '@thales/loading';
import tableSummary from '@/utils/tableSummary';
import unitNumber, { BIG_NUMBER } from '@/utils/unitNumber';
import { deliverOrderRecord, deliverOrderRecordExport } from './api';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import summaryConfig from './config';

export default {
  name: 'OutWarehouseParticulars',
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    handlePaginationChange() {
      this.getTableData();
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    async getTableData() {
      const params = this.getSearchParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await deliverOrderRecord(pagination, params);
      this.tableData = result.records.map((item) => {
        const {
          bigUnitNumber, smallUnitNumber,
        } = unitNumber.calculate({ ...item, smallUnitNumber: item.amount }, BIG_NUMBER);
        return {
          ...item,
          bigUnitNumber,
          smallUnitNumber,
        };
      });
      this.pagination.total = result.total;
    },
    handleExport() {
      const params = this.getSearchParams();
      deliverOrderRecordExport(params);
    },
    getSearchParams() {
      const {
        createTime, bookingTime, deliverTime, ...otherQuery
      } = this.searchQuery;

      const [beforeCreateTime, afterCreateTime] = createTime || [];
      const [beforeBookingTime, afterBookingTime] = bookingTime || [];
      const [beforeDeliverTime, afterDeliverTime] = deliverTime || [];

      return {
        ...otherQuery,
        beforeCreateTime,
        afterCreateTime,
        beforeBookingTime,
        afterBookingTime,
        beforeDeliverTime,
        afterDeliverTime,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.table-input {
  :deep(.el-input) {
    width: 50px;
  }
}

.footer {
  border-top: 1px solid #eee;
  padding: 8px 24px 8px 0;
  background-color: #fff;
  text-align: right;
}

</style>
